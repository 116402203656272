@import url('https://fonts.googleapis.com/css?family=Raleway:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i');


@font-face {
    font-family: 'Orkney';
    src: url('../fonts/Orkney-Medium.woff2') format('woff2'),
        url('../fonts/Orkney-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Orkney';
    src: url('../fonts/Orkney-Bold.woff2') format('woff2'),
        url('../fonts/Orkney-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Orkney';
    src: url('../fonts/Orkney-Light.woff2') format('woff2'),
        url('../fonts/Orkney-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Orkney';
    src: url('../fonts/Orkney-Regular.woff2') format('woff2'),
        url('../fonts/Orkney-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../fonts/Rajdhani-Regular.woff2') format('woff2'),
        url('../fonts/Rajdhani-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../fonts/Rajdhani-Medium.woff2') format('woff2'),
        url('../fonts/Rajdhani-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../fonts/Rajdhani-Light.woff2') format('woff2'),
        url('../fonts/Rajdhani-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../fonts/Rajdhani-Bold.woff2') format('woff2'),
        url('../fonts/Rajdhani-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../fonts/Rajdhani-Semibold.woff2') format('woff2'),
        url('../fonts/Rajdhani-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/Montserrat-SemiBold.woff') format('woff'), 
    url('../fonts/Montserrat-SemiBold.ttf')  format('truetype'), 
    url('../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Montserrat-Regular';
    src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/Montserrat-Regular.woff') format('woff'),
    url('../fonts/Montserrat-Regular.ttf')  format('truetype'), 
    url('../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  
  
  @font-face {
    font-family: 'Montserrat-Light';
    src: url('../fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),  
    url('../fonts/Montserrat-Light.otf')  format('opentype'),
    url('../fonts/Montserrat-Light.woff') format('woff'), 
    url('../fonts/Montserrat-Light.ttf')  format('truetype'), 
    url('../fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  
  @font-face {
    font-family: 'Proxima Nova Th';
    src: url('../fonts/ProximaNovaT-Thin.woff2') format('woff2'),
        url('../fonts/ProximaNovaT-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Alt Bl';
    src: url('../fonts/ProximaNovaA-Black.woff2') format('woff2'),
        url('../fonts/ProximaNovaA-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Alt Rg';
    src: url('../fonts/ProximaNovaA-Bold.woff2') format('woff2'),
        url('../fonts/ProximaNovaA-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova ScOsf Th';
    src: url('../fonts/ProximaNovaS-Thin.woff2') format('woff2'),
        url('../fonts/ProximaNovaS-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Alt Cn Lt';
    src: url('../fonts/ProximaNovaACond-Semibold.woff2') format('woff2'),
        url('../fonts/ProximaNovaACond-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('../fonts/ProximaNova-Semibold.woff2') format('woff2'),
        url('../fonts/ProximaNova-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Alt Rg';
    src: url('../fonts/ProximaNovaA-Regular.woff2') format('woff2'),
        url('../fonts/ProximaNovaA-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_altblack';
    src: url('../fonts/proxima_nova_altblack.woff2') format('woff2'),
        url('../fonts/proxima_nova_altblack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_ththin';
    src: url('../fonts/proxima_nova_ththin.woff2') format('woff2'),
        url('../fonts/proxima_nova_ththin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_ltsemibold';
    src: url('../fonts/proxima_nova_ltsemibold.woff2') format('woff2'),
        url('../fonts/proxima_nova_ltsemibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_alt_rgbold';
    src: url('../fonts/proxima_nova_alt_rgbold.woff2') format('woff2'),
        url('../fonts/proxima_nova_alt_rgbold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_scosfthin';
    src: url('../fonts/proxima_nova_scosfthin.woff2') format('woff2'),
        url('../fonts/proxima_nova_scosfthin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_alt_rgregular';
    src: url('../fonts/proxima_nova_alt_rgregular.woff2') format('woff2'),
        url('../fonts/proxima_nova_alt_rgregular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova_alt_condensedSBd';
    src: url('../fonts/proxima_nova_alt_condensedSBd.woff2') format('woff2'),
        url('../fonts/proxima_nova_alt_condensedSBd.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Alt Rg';
    src: url('../fonts/ProximaNovaA-Bold.woff2') format('woff2'),
        url('../fonts/ProximaNovaA-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Alt Rg';
    src: url('../fonts/ProximaNovaA-Regular.woff2') format('woff2'),
        url('../fonts/ProximaNovaA-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Alt Bl';
    src: url('../fonts/ProximaNovaA-Black.woff2') format('woff2'),
        url('../fonts/ProximaNovaA-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('../fonts/ProximaNovaT-Thin.woff2') format('woff2'),
        url('../fonts/ProximaNovaT-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova ScOsf Th';
    src: url('../fonts/ProximaNovaS-Thin.woff2') format('woff2'),
        url('../fonts/ProximaNovaS-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Alt Rg';
    src: url('../fonts/ProximaNovaA-Bold.woff2') format('woff2'),
        url('../fonts/ProximaNovaA-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Th';
    src: url('../fonts/ProximaNovaT-Thin.woff2') format('woff2'),
        url('../fonts/ProximaNovaT-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('../fonts/ProximaNova-Semibold.woff2') format('woff2'),
        url('../fonts/ProximaNova-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Alt Bl';
    src: url('../fonts/ProximaNovaA-Black.woff2') format('woff2'),
        url('../fonts/ProximaNovaA-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}


@font-face {
    font-family:'Poppins Light';
    font-style: normal;
    font-weight: 300;
    src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family:'Poppins Light';
    font-style: normal;
    font-weight: 300;
    src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family:'Poppins Light';
    font-style: normal;
    font-weight: 300;
    src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family:'Poppins Regular';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family:'Poppins Regular';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family:'Poppins Regular';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v5/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family: 'Poppins Medium';
    font-style: normal;
    font-weight: 500;
    src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Poppins Medium';
    font-style: normal;
    font-weight: 500;
    src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Poppins Medium';
    font-style: normal;
    font-weight: 500;
    src: local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* devanagari */
  @font-face {
    font-family:'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }
  /* latin-ext */
  @font-face {
    font-family:'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family:'Poppins Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v5/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  