@media (min-width: 768px) {
  .hideLarge {
    display: none !important;
  }
  .modal-footer .btn.w-50 {
    max-width: calc(50% - 7px);
  }
  .Logged main.mainContent {
    min-height: calc(100vh - 322px);
  }
  /* .editProfileSection .nav-tabs */
  .editProfileSection .nav-item {
    width: 25%;
    padding: 0px !important;
    margin-top: 7px;
  }
  .editProfileSection .nav-item a {
    margin: 0 3px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .animationWalletBox {
    transform: scale(0.7);
    left: -39px;
  }
  .colTopSpcae,
  .newsTopSpcae {
    margin: 15px 0;
  }
  .nav-tabs.globalTab .nav-link {
    padding: 10px 8px;
  }
  .nav-tabs.globalTab .nav-item.max-WT-20Per a {
    min-height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .nav-tabs.globalTab .nav-item.max-WT-20Per a {
    min-height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1366px) {
  .container-common {
    max-width: 1200px;
  }
  .menu ul li a {
    font-size: 13px;
  }
  /* .menu ul li{padding:15px 10px;} */
}

@media (max-width: 1199px) {
  .container-common {
    max-width: 991px;
  }
}

@media (max-width: 991px) {
  /* .dropdown.common_ack {
            position: absolute;
            right: 220px;
        } */
  body.loged header {
    padding-right: 0px;
  }
  header > nav {
    padding-right: 50px;
  }
  .mob-menu {
    display: block;
    z-index: 1;
  }
  .close-menu {
    display: block;
  }
  .menu {
    opacity: 0;
    visibility: hidden;
    backface-visibility: hidden;
    position: fixed;
    top: 0px;
    right: 0px;
    margin-right: -100%;
    background: #fff;
    padding-top: 50px;
    width: 300px;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    z-index: 1;
    transition: 400ms;
  }
  .menu ul li {
    display: block;
    /* padding: 15px 20px; */
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0 0 8px #ddd;
    margin: 16px 0;
  }
  .menu ul li a {
    color: var(--black-color);
  }
  .menu-slide {
    margin-right: 0;
    opacity: 1;
    visibility: visible;
    backface-visibility: visible;
    transition: 400ms;
    z-index: 9999;
    background: var(--white-color);
  }
  .steps-main-wrapper {
    flex-wrap: wrap !important;
  }
  .step-left,
  .step-right {
    order: 2;
    padding: 3rem 1rem 0;
  }
  .steps-listing li {
    padding-right: 0px;
    padding-left: 70px;
  }
  .steps-listing li span.step-number {
    left: 0;
    right: auto;
  }
  .steps-listing li:before {
    left: 26px;
    right: auto;
  }
  .step-left {
    text-align: left;
  }
  /* .login-box{margin-right:40px;} */
  .CoinColSpace + .CoinColSpace {
    margin-top: 30px;
  }
  .fixedBox {
    max-width: 720px;
  }
  .nav-tabs.globalTab .nav-link {
    font-size: 12px;
  }
  .customTable {
    overflow-x: scroll;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 15px 0px;
  }
  .buySellBox {
    padding: 10em 0em;
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }
  .buySellBox2 {
    padding: 0;
  }

  /* .commonSectionStyle {padding: 0 0 3em !important;} */
  .homecards {
    display: flex;
    margin-top: 10px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .tabox {
    padding: 5px !important;
    width: 30%;
    margin-bottom: 8px;
  }
  .taboxActive {
    width: 30%;
  }
  .tradeAdBg {
    margin-bottom: 0;
  }
  .formInnerNew {
    padding: 0 25px 20px;
    display: block !important;
  }
  .loginbox,
  .signupbox {
    display: block;
    margin: 0;
    height: auto;
    margin: 100px 0 0;
  }
  .box_4,
  .box_1,
  .box_3 {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
  }
  .box_2 {
    padding: 40px 25px;
    border-radius: 0px 0px 8px 8px;
    width: 100%;
  }
  .pdBox {
    position: inherit;
    padding: 0 20px 20px;
  }
  .pdbox2 {
    position: inherit;
    padding: 0 20px 20px;
    top: 0;
  }
  .qrCode a {
    margin-left: 25%;
  }
  .refHeader {
    margin-top: 0;
    padding: 0;
  }
  .refTxt {
    width: 100%;
  }
  h2 {
    font-size: 1.5rem !important;
  }
  .socailTxt {
    font-size: 1.5rem !important;
    line-height: 30px;
  }
  .mechantBnr {
    background-size: 100% 95%;
    height: auto;
    padding-top: 10px;
  }
  .rowSpace {
    padding: 2em 0 0;
  }
  .rowSpace3,
  .rowSpace4 {
    padding: 6em 0 0;
  }
  .width85 {
    width: 100%;
  }
  .img_1 {
    width: 100%;
    float: none;
  }
  .text1 {
    padding: 30px 0px 30px 0px;
    width: 100% !important;
  }
  .text2,
  .text4 {
    padding: 0px;
    width: 100%;
  }
  .refImg,
  .refImgLft {
    margin: 0 auto;
    float: none;
    width: 75%;
  }
  .refTxt3 {
    text-align: justify;
    float: none;
    padding: 30px 0 10px;
    width: 100%;
  }
  .text3 {
    padding: 0px 0px 30px 0px;
    text-align: justify;
    width: 100%;
  }
  .mer_logo {
    bottom: 20%;
    right: 12%;
    width: 30%;
  }
  .rowSpace2 {
    padding: 0;
  }
  .rowSpace4 {
    padding: 0px 0 20px;
  }
  .merImgLft {
    float: none;
    width: 65%;
    margin: 0 auto;
  }
  .merImgRyt {
    float: none;
    width: 70%;
    margin: 0 auto;
  }
  .socialImg {
    width: 50%;
    margin: 10px auto;
  }
  .socMedGrp {
    padding: 0;
  }
  .card_22 {
    height: 70px;
  }
  .socialIcon {
    width: 50px;
    height: 50px;
    margin: 10px auto;
  }
  .socialTweet {
    width: 50px;
    height: 35px;
    margin: 20px auto;
  }
  .socialPadd {
    padding: 2em 0em;
  }
  .footerApp {
    justify-content: center;
  }
  .imgGrp0 {
    width: 65%;
    height: 270px;
  }
  .loginbox2 {
    display: block;
    height: auto;
  }
  .box_lft {
    width: 100%;
  }
  .box_ryt {
    width: 100%;
    padding: 40px 10px;
  }
  .referlist {
    padding: 10px;
  }
  .btcrefAmnt {
    float: none;
    margin-top: 10px;
  }
  .modal button.close {
    color: #3e5c85;
    font-size: 26px;
  }
  .pdBox3 {
    padding: 0 20px 20px;
    position: inherit;
  }
  .refBnr {
    height: 560px;
    /* height: 650px; */
  }
  .spanpad {
    padding: 5px;
  }
  .colcentr {
    padding: 0;
  }
  .giftImg {
    width: 100%;
    float: none;
  }
  .giftPad {
    padding: 20px 0em 0;
  }
  .gftTxt {
    width: 100%;
    margin: 10px auto 0;
    font-size: 12px;
  }
  .width80 {
    width: 100%;
  }
  .gftTxt2 {
    text-align: justify;
    width: 100%;
    margin: 20px auto;
    font-family: "Quicksand-Medium";
    font-size: 16px;
  }
  .cnterImg {
    width: 80%;
  }
  .giftPad2 {
    padding: 20px 0em;
    margin: 0;
  }
  .goodsTitle {
    padding: 0;
    margin: 0;
  }
  .goodsImg {
    width: 80%;
    margin: 0 auto 20px;
  }
  .bankLayer {
    background-position: 136% 65%, -30% 30%;
    padding: 0;
  }
  .altLayer {
    background-position: 135% -340%, -30% 30%;
    padding: 0;
  }
  .cashLayer {
    background-position: 132% 50%, -38% -100%;
    padding: 0;
  }
  .goodsLayer {
    background-position: 132% -100%, -30% 145%;
    padding: 0;
  }
  .cashImg {
    width: 90%;
    margin: auto;
  }
  .altSection {
    width: 100%;
  }
  .altSection .bankImg {
    height: 360px;
  }
  .fontsize {
    font-size: 24px !important;
  }
  .bnkImgTitle {
    margin-bottom: 0;
  }
  .width75 {
    width: 100%;
  }
  .centerSection {
    width: 100%;
  }
  .bnkTxt {
    text-align: justify;
  }
  .cashSection {
    width: 100%;
  }
  .cashSection .bankImg {
    width: 100%;
  }
  .width65 {
    width: 100%;
  }
  .width55 {
    width: 65%;
  }
  .refhyt {
    height: 800px !important;
  }
  .imgGrp1 {
    width: 40%;
    height: 100px;
    margin-top: 35px;
  }
  .imgGrp2 {
    width: 80%;
    height: 260px;
    margin: 0 auto;
  }
  .imgGrpTxt {
    padding: 15px 15px;
  }
  .imgGrpTxt h2 {
    margin-bottom: 5px;
  }
  .imgGrp3 {
    width: 80%;
    height: 100px;
  }
  .mobvw {
    padding-top: 4em !important;
    padding-bottom: 4em !important;
  }
  .graphBox {
    padding: 0;
    height: 300px;
    overflow: auto;
  }
  .refHeader .txt2 {
    margin: 0 12px 35px;
    font-size: 36px !important;
  }
  .mobImg {
    float: none;
    margin: 20px auto 35px;
    width: 35%;
  }
  .mobImg2 {
    width: 75%;
    margin: -40px auto 40px;
  }
  .flexRev {
    flex-wrap: wrap-reverse;
  }
  .taboxActive3,
  .tabox3 {
    width: 30%;
    padding: 2px !important;
  }
  .mar_auto2 {
    margin: 10px !important;
  }
  .dBlock {
    display: block !important;
  }
  .coinDetailbox {
    flex-wrap: nowrap;
  }
  .coinDetailLeft {
    padding-left: 15px;
  }
  /* .colPad {padding: 20px 5px 0;} */
  .tradeAln {
    width: 40%;
  }
  .tradeStrip {
    padding: 25px 20px;
    display: block;
    width: 100%;
  }
  /* .cardHead h4 {padding: 10px 20px;} */
  .termpad {
    padding: 25px;
  }
  .tagLine1 {
    text-align: start;
    font-size: 24px;
    width: 100%;
  }
  .home1-img {
    width: auto;
    float: none;
    margin: auto;
    height: 90%;
    width: 75%;
  }
  .hytHome {
    height: 995px;
  }
  .bnrMar {
    top: 589px;
    right: 25px;
    /* margin-top: 2%;
            margin-right: 0px; */
  }
  .salrymar {
    margin-bottom: 15px;
  }
  .captaMar {
    margin: 0 25px;
  }
  .widthStyle {
    width: 100% !important;
  }
  /* .tableFilter11 {margin-top: 0;} */
  .frstBuyr {
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }
  .banner3 {
    padding: 5em 0em 0;
    background-position: 50% 20%;
  }
  .newsTopSpcae {
    margin-bottom: 15px;
  }
  .footerHead {
    height: auto;
    padding: 30px;
  }
  .rowPd {
    padding: 145px 40px 40px;
  }
  .footLogo {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .footLogo img {
    width: 50%;
  }
  .par {
    padding: 0 0 2em;
    justify-content: center;
    flex-wrap: wrap;
  }
  .card_11 {
    height: 100px;
    width: 45%;
    margin: 6px;
  }
  .boxMar {
    margin: 20px auto 0px;
    width: 35px;
    height: 25px;
  }
  .txtBox {
    padding: 22px 10px 30px 10px;
    justify-content: space-evenly;
  }
  .equalto {
    margin-top: 0;
  }
  .common_ack .dropdown-menu {
    width: 150px;
  }
  .onCoinbaazar p {
    margin-left: 0;
    text-align: left;
  }
  .menu ul li a {
    color: var(--popUpBW-color) !important;
  }
  .jusCentr {
    justify-content: center;
  }
  .marBtm {
    margin-bottom: 20px;
    margin-top: 35px;
  }
  .height550,
  .height500,
  .minHeight500 {
    height: auto !important;
  }
  .d-grid {
    display: grid;
  }
  /* .tabList2{justify-content: space-between;} */
  .tabox2,
  .taboxActive2 {
    width: fit-content;
    margin-bottom: 8px;
    margin-right: 4px;
    height: 35px;
    padding: 5px !important;
  }
  .invoiceSearch {
    width: 200px;
  }
  .pad10 {
    padding: 10px 5px !important;
  }
  .marTp {
    margin-top: 90px !important;
  }

  .marTp1 {
    margin-top: 90px !important;
  }
  .detailBox {
    margin: 0 15px;
  }
  .header.header-bg .menu ul li a {
    color: #3e5c85 !important;
  }
  .cardHeader {
    text-align: center;
  }
  .cardHeader h1 {
    font-size: 30px;
  }
  .balanceImg,
  .redeemImg {
    margin: 0 auto;
  }
  .giftIns li {
    margin-left: 20px;
  }
  .prodImg .boxsize2 {
    width: 100%;
    margin-left: 0;
  }
  .cardtext h2 {
    margin: 35px 0;
  }
  .giftTabs {
    padding: 0;
  }
  .cardStr {
    padding-top: 0;
  }
  .myCard {
    margin: 0;
  }
  .tot_amnt {
    padding: 10px 4px;
    font-size: 16px;
  }
  .appBtn {
    height: 30px;
  }
  /* .fixedBox{width: 90%;} */
  /* .fixedBox .slicker-slider{width: 80%;
            margin: 0 auto;} */
  .captchImg {
    /* transform: scale(0.8);
            margin-left: -31px; */
  }
  #g-recaptcha > div {
    transform: scale(0.85);
    margin-left: -23px;
  }
  /* .login-box .btn.btn-secondary{width:35px;} */
  .formBox .form-group.pl20 {
    padding-left: 0 !important;
  }
  .commonSectionStyle3 {
    padding: 5em 0;
  }
  .logo {
    width: 70px;
    height: 70px;
  }
  .logo img {
    max-width: 90px;
  }
  .loggin-box {
    display: inherit;
    margin-top: 40px;
    width: 300px;
    padding: 3px 0px 0px 13px;
  }
  .goog-te-gadget .goog-te-combo {
    width: 95px;
    font-size: 10px;
  }
  .gogleLang {
    margin-left: 5px;
  }
  /* .login-box{max-width:172px;}	 */
  .btn.btnHeader {
    padding: 12px 0px;
    min-width: 75px;
    font-size: 12px;
  }
  .dashboardIcon {
    margin-left: 10px;
  }
  .btn-bell {
    margin-right: 15px;
  }
  .topBanner-content h1,
  .sectionHead h2,
  .tableBoxHead h4 {
    font-size: 20px;
  }
  .topBanner-content {
    min-height: 390px;
  }
  .commonSectionStyle {
    padding: 0;
  }
  .commonSectionStyle2 {
    padding: 0;
  }
  .walletAnimateSection {
    padding: 3em 0;
  }
  .itemImg {
    width: 30%;
  }
  .itemInfo {
    width: 70%;
    padding: 25px 10px;
  }
  .tableFilter .d-flex {
    text-align: center;
    justify-content: center;
  }
  .filterInlineField {
    width: 100%;
    margin: 0 0 10px;
    padding-right: 0;
  }
  .colTopSpcae + .colTopSpcae,
  .newsTopSpcae + .newsTopSpcae {
    margin-top: 30px;
  }
  .animationWalletBox {
    width: 250px;
    height: 250px;
  }
  .circleIcon {
    width: 45px;
    height: 45px;
    line-height: 39px;
  }
  .circleIcon.circleIcon1 {
    top: -20px;
  }
  .circleIcon.circleIcon2 {
    right: -7px;
    top: 40px;
  }
  .circleIcon.circleIcon3 {
    right: -11px;
    bottom: 50px;
  }
  .circleIcon.circleIcon4 {
    bottom: -20px;
  }
  .circleIcon.circleIcon5 {
    left: -11px;
    bottom: 50px;
  }
  .circleIcon.circleIcon6 {
    left: -7px;
    top: 40px;
  }
  .animationWalletBox .sectionHead {
    max-width: 190px;
  }
  .animationWalletBox .sectionHead h2 {
    font-size: 18px;
  }
  .circleIcon img {
    max-width: 25px;
  }
  .tableFilter .btn {
    min-height: 45px;
  }
  span.footLogo {
    display: block;
    top: auto;
    margin: 0 auto 25px;
  }
  footer p.copyRight {
    margin: 15px 0 0;
  }
  .addressBox {
    margin-top: 15px;
  }
  .largeImg {
    margin-top: 30px;
  }
  .animationWalletBox {
    margin-bottom: 45px !important;
  }
  .createAccountBox span {
    margin: 0 0 10px;
  }
  .formLinksInfo {
    font-size: 12px;
  }
  .cardBox {
    padding: 15px;
  }
  .coinDetailLeft,
  .coinDetailRight {
    width: 100%;
  }
  .modal-dialog {
    margin: 0.8rem auto;
    width: 95%;
  }

  .nav-tabs.globalTab .max-WT-33Per,
  .nav-tabs.globalTab .max-WT-20Per,
  .nav-tabs.globalTab .max-WT-18Per {
    max-width: 100% !important;
  }
  .nav-tabs.globalTab .nav-item.max-WT-33Per + .nav-item.max-WT-33Per,
  .nav-tabs.globalTab .nav-item.max-WT-18Per + .nav-item.max-WT-18Per,
  .nav-tabs.globalTab .nav-item.max-WT-20Per + .nav-item.max-WT-20Per {
    padding-left: 0;
  }

  .walletSection .cardBox.p30 {
    padding: 15px !important;
  }
  .tableBoxHead {
    padding: 0;
  }
  .tableFilter2 {
    justify-content: center;
  }
  .datepickerField label {
    font-size: 13px;
  }
  .datepickerField {
    margin-bottom: 10px;
  }
  .modal-footer .btn.w-50 {
    min-width: 100%;
  }
  .modal-footer .btn.w-50 + .btn.w-50 {
    margin-top: 10px;
  }
  .tableBoxHead h4 {
    font-size: 18px !important;
  }
  .cardBox.p30 {
    padding: 15px !important;
  }
  .profileViewField {
    font-size: 13px;
  }
  .viewFieldLeft {
    margin-bottom: 5px;
  }
  .viewFieldLeft,
  .viewFieldRight {
    width: 100%;
  }
  .feedbackBox {
    padding-top: 15px;
  }
  .feedTime {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 10px;
    margin-top: 0px;
  }
  .feedbackBox + .feedbackBox {
    margin-top: 25px;
  }
  .feedUserRight p {
    font-size: 13px;
  }
  /* .fixedBox{} */
  .editProfileSection .nav-item {
    width: 50%;
  }
  .editProfileSection .nav-tabs.globalTab .nav-link {
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uploadWithButton {
    text-align: center;
    justify-content: space-around;
  }
  .uploadWithButton .kycfile-uplaod {
    max-width: 100%;
    margin-bottom: 10px;
  }
  .uploadWithButton .ml-auto {
    margin-left: 0 !important;
  }

  .fixedBox {
    position: static;
    padding-top: 100px;
    top: 100px;
  }
  .staticContentSection {
    margin-top: 100px;
  }
  .fixedBox .globalTab {
    width: 80%;
    position: absolute;
    left: 9%;
    top: 185px;
    background: #fff;
    padding: 15px;
    z-index: 9;
    transition: 0.5s;
  }
  .fixedBox .globalTab.slide {
    left: 0;
    transition: 0.5s;
  }
  .dropdown-menu.head-drop-down {
    left: -115px !important;
  }
  .dropdown-menu.head-drop-down.show:before{
    right: 145px !important;
  }
}

@media (max-width: 360px) {
  .walletSection .cardBox .btn.btnGreen {
    font-size: 13px;
  }
  .tableFilter2 {
    text-align: center;
    justify-content: center;
  }
  .datepickerField {
    margin: 0 0 10px;
    width: 100%;
  }
  .datepickerField label {
    text-align: left;
    min-width: 34px;
  }
  .tableFilter2 .form-control.datepicker {
    font-size: 13px;
  }
  .tableBoxHead h4 {
    font-size: 16px !important;
  }
  .dropdown-menu.head-drop-down {
    left: -115px !important;
  }
  .dropdown-menu.head-drop-down.show:before{
    right: 145px !important;
  }
}
