/* ==============Lov Patsariya UI Developer=========== */
@import url("fontawesome-all.min.css");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,500,600,700,800");
@import url("fonts.css");

*,
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
}

body.stopScroll {
  overflow: hidden !important;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="date"],
input[type="time"],
input[type="file"],
textarea,
button,
input[type="password"],
input[type="tel"],
select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  /* box-shadow:none!important; */
  border-radius: 5px;
}

input::-moz-focus-inner {
  border: 0;
  outline: none !important;
}

select:-moz-focusring {
  outline: none !important;
}

.select_style1.form-control,
.select_style2.form-control {
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  background-size: 13px auto;
  border-color: var(--border-color);
  padding-right: 20px;
  box-shadow: none;
}

/* .select_style1.form-control {background-image: url("../images/down_arrow.png");}
.select_style2.form-control {background-image: url("../images/select-arrow.png");} */
/* =============Stylish Select========== */
a,
input,
button,
a:focus,
input:focus,
button:focus {
  outline: none !important;
}

a,
a:hover,
a:focus {
  color: var(--hover-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Tommysoft-Bold";
  font-weight: 500;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px !important;
}

img {
  max-width: 100%;
}

li {
  list-style-type: none;
}

.margin0 {
  margin: 0 !important;
}

.marAuto {
  margin: 0 auto;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.txtLeft {
  text-align: left;
}

.txtRyt {
  text-align: right;
}

.p0 {
  padding: 0px !important;
}

.p5 {
  padding: 5px !important;
}

.p10 {
  padding: 10px !important;
}

.p15 {
  padding: 15px !important;
}

.p20 {
  padding: 20px !important;
}

.p25 {
  padding: 25px !important;
}

.p30 {
  padding: 30px !important;
}

.p35 {
  padding: 35px !important;
}

.paddT0 {
  padding-top: 0px !important;
}

.m0 {
  margin: 0px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin: 15px !important;
}

.pLR5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pLR6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.pLR10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pLR15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pl0 {
  padding-left: 0px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pr0 {
  padding-right: 0px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pr30 {
  padding-left: 30px !important;
}

.pr0 {
  padding-right: 0px !important;
}

.pr1 {
  padding-right: 1px !important;
}

.pr2 {
  padding-right: 0px !important;
}

.pr3 {
  padding-right: 3px !important;
}

.pr4 {
  padding-right: 4px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pt0 {
  padding-top: 0px !important;
}

.pt1 {
  padding-top: 1px !important;
}

.pt2 {
  padding-top: 0px !important;
}

.pt3 {
  padding-top: 3px !important;
}

.pt4 {
  padding-top: 4px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pt55 {
  padding-top: 55px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mt1 {
  margin-top: 1px !important;
}

.mt2 {
  margin-top: 2px !important;
}

.mt3 {
  margin-top: 3px !important;
}

.mt4 {
  margin-top: 4px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt6 {
  margin-top: 6px !important;
}

.mt7 {
  margin-top: 7px !important;
}

.mt8 {
  margin-top: 8px !important;
}

.mt9 {
  margin-top: 9px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb1 {
  margin-bottom: 1px !important;
}

.mb2 {
  margin-bottom: 2px !important;
}

.mb3 {
  margin-bottom: 3px !important;
}

.mb3 {
  margin-bottom: 4px !important;
}

.mb4 {
  margin-bottom: 5px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb6 {
  margin-bottom: 6px !important;
}

.mb7 {
  margin-bottom: 7px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}

.mb9 {
  margin-bottom: 9px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb65 {
  margin-bottom: 65px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb75 {
  margin-bottom: 75px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mMinLR6 {
  margin-left: -6px !important;
  margin-right: -6px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.mr1 {
  margin-right: 1px !important;
}

.mr2 {
  margin-right: 2px !important;
}

.mr3 {
  margin-right: 3px !important;
}

.mr4 {
  margin-right: 4px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr6 {
  margin-right: 6px !important;
}

.mr7 {
  margin-right: 7px !important;
}

.mr8 {
  margin-right: 8px !important;
}

.mr9 {
  margin-right: 6px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.ml0 {
  margin-left: 0px !important;
}

.ml1 {
  margin-left: 1px !important;
}

.ml2 {
  margin-left: 2px !important;
}

.ml3 {
  margin-left: 3px !important;
}

.ml4 {
  margin-left: 4px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.mTB0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mTB5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mTB0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mTB5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mt-min0 {
  margin-top: -0px !important;
}

.mt-min1 {
  margin-top: -1px !important;
}

.mt-min2 {
  margin-top: -2px !important;
}

.mt-min3 {
  margin-top: -3px !important;
}

.mt-min4 {
  margin-top: -4px !important;
}

.mt-min5 {
  margin-top: -5px !important;
}

.mt-min6 {
  margin-top: -6px !important;
}

.mt-min7 {
  margin-top: -7px !important;
}

.mt-min8 {
  margin-top: -8px !important;
}

.mt-min9 {
  margin-top: -9px !important;
}

.mt-min10 {
  margin-top: -10px !important;
}

.mMR-15 {
  margin-right: -15px !important;
}

.mML-15 {
  margin-left: -15px !important;
}

.mMR-10 {
  margin-right: -10px !important;
}

.mML-10 {
  margin-left: -10px !important;
}

.pAbsolute {
  position: absolute !important;
}

.pRelative {
  position: relative !important;
  cursor: pointer;
  background-color: var(--primary-color);
}

.pStatic {
  position: static !important;
}

.top0 {
  top: 0px !important;
}

.top1 {
  top: 1px !important;
}

.top2 {
  top: 2px !important;
}

.top3 {
  top: 3px !important;
}

.top4 {
  top: 4px !important;
}

.top5 {
  top: 5px !important;
}

.top10 {
  top: 10px !important;
}

.top15 {
  top: 15px !important;
}

.top20 {
  top: 20px !important;
}

.top25 {
  top: 25px !important;
}

.top30 {
  top: 30px !important;
}

.top35 {
  top: 35px !important;
}

.top40 {
  top: 40px !important;
}

.top45 {
  top: 45px !important;
}

.top50 {
  top: 50px !important;
}

.top55 {
  top: 55px !important;
}

.top60 {
  top: 60px !important;
}

.top65 {
  top: 65px !important;
}

.top70 {
  top: 70px !important;
}

.top75 {
  top: 75px !important;
}

.top80 {
  top: 80px !important;
}

.top85 {
  top: 85px !important;
}

.top90 {
  top: 90px !important;
}

.top95 {
  top: 95px !important;
}

.top100 {
  top: 100px !important;
}

.topmin-0 {
  top: -0px !important;
}

.topmin-1 {
  top: -1px !important;
}

.topmin-2 {
  top: -2px !important;
}

.topmin-3 {
  top: -3px !important;
}

.topmin-4 {
  top: -4px !important;
}

.topmin-5 {
  top: -5px !important;
}

.topmin-10 {
  top: -10px !important;
}

.left0 {
  left: 0px !important;
}

.left1 {
  left: 1px !important;
}

.left2 {
  left: 2px !important;
}

.left3 {
  left: 3px !important;
}

.left4 {
  left: 4px !important;
}

.left5 {
  left: 5px !important;
}

.left10 {
  left: 10px !important;
}

.left15 {
  left: 15px !important;
}

.left20 {
  left: 20px !important;
}

.left25 {
  left: 25px !important;
}

.left30 {
  left: 30px !important;
}

.left35 {
  left: 35px !important;
}

.left40 {
  left: 40px !important;
}

.left45 {
  left: 45px !important;
}

.left50 {
  left: 50px !important;
}

.left55 {
  left: 55px !important;
}

.left60 {
  left: 60px !important;
}

.left65 {
  left: 65px !important;
}

.left70 {
  left: 70px !important;
}

.left75 {
  left: 75px !important;
}

.left80 {
  left: 80px !important;
}

.left85 {
  left: 85px !important;
}

.left90 {
  left: 90px !important;
}

.left95 {
  left: 95px !important;
}

.left100 {
  left: 100px !important;
}

/*Global width css*/
.max-WT-100 {
  width: 100%;
  max-width: 100px !important;
}

.max-WT-130 {
  width: 100%;
  max-width: 130px !important;
}

.max-WT-170 {
  width: 100%;
  max-width: 170px !important;
}

.max-WT-230 {
  width: 100%;
  max-width: 230px !important;
}

.max-WT-320 {
  width: 100%;
  max-width: 320px !important;
}

.max-WT-360 {
  width: 100%;
  max-width: 360px !important;
}

.max-WT-480 {
  width: 100%;
  max-width: 480px !important;
}

.max-WT-20 {
  width: 100%;
  max-width: 20px !important;
}

.max-WT-30 {
  width: 100%;
  max-width: 30px !important;
}

.max-WT-40 {
  width: 100%;
  max-width: 40px !important;
}

.max-WT-50 {
  width: 100%;
  max-width: 50px !important;
}

.max-WT-60 {
  width: 100%;
  max-width: 60px !important;
}

.max-WT-70 {
  width: 100%;
  max-width: 70px !important;
}

.max-WT-80 {
  width: 100%;
  max-width: 80px !important;
}

.max-WT-90 {
  width: 100%;
  max-width: 90px !important;
}

.max-WT-100 {
  width: 100%;
  max-width: 100px !important;
}

.max-WT-110 {
  width: 100%;
  max-width: 110px !important;
}

.max-WT-120 {
  width: 100%;
  max-width: 120px !important;
}

.max-WT-130 {
  width: 100%;
  max-width: 130px !important;
}

.max-WT-140 {
  width: 100%;
  max-width: 140px !important;
}

.max-WT-150 {
  width: 100%;
  max-width: 150px !important;
}

.max-WT-160 {
  width: 100%;
  max-width: 160px !important;
}

.max-WT-170 {
  width: 100%;
  max-width: 170px !important;
}

.max-WT-180 {
  width: 100%;
  max-width: 180px !important;
}

.max-WT-190 {
  width: 100%;
  max-width: 190px !important;
}

.max-WT-200 {
  width: 100%;
  max-width: 200px !important;
}

.max-WT-250 {
  width: 100%;
  max-width: 250px !important;
}

.max-WT-290 {
  width: 100%;
  max-width: 290px !important;
}

.max-WT-300 {
  width: 100%;
  max-width: 300px !important;
}

.max-WT-325 {
  width: 100%;
  max-width: 325px !important;
}

.max-WT-350 {
  width: 100%;
  max-width: 350px !important;
}

.max-WT-400 {
  width: 100%;
  max-width: 400px !important;
}

.max-WT-440 {
  width: 100%;
  max-width: 440px !important;
}

.max-WT-450 {
  width: 100%;
  max-width: 450px !important;
}

.max-WT-500 {
  width: 100%;
  max-width: 500px !important;
}

.max-WT-550 {
  width: 100%;
  max-width: 550px !important;
}

.max-WT-600 {
  width: 100%;
  max-width: 600px !important;
}

.max-WT-6050 {
  width: 100%;
  max-width: 600px !important;
}

.max-WT-650 {
  width: 100%;
  max-width: 650px !important;
}

.max-WT-700 {
  width: 100%;
  max-width: 700px !important;
}

.max-WT-750 {
  width: 100%;
  max-width: 750px !important;
}

.max-WT-767 {
  width: 100%;
  max-width: 767px !important;
}

.max-WT-800 {
  width: 100%;
  max-width: 800px !important;
}

.max-WT-850 {
  width: 100%;
  max-width: 850px !important;
}

.max-WT-900 {
  width: 100%;
  max-width: 900px !important;
}

.max-WT-991 {
  width: 100%;
  max-width: 991px !important;
}

.max-WT-950 {
  width: 100%;
  max-width: 950px !important;
}

.max-WT-1000 {
  width: 100%;
  max-width: 1000px !important;
}

.max-WT-1050 {
  width: 100%;
  max-width: 1050px !important;
}

.max-WT-1100 {
  width: 100%;
  max-width: 1100px !important;
}

.max-WT-1130 {
  width: 100%;
  max-width: 1130px !important;
}

.max-WT-1200 {
  width: 100%;
  max-width: 1200px !important;
}

.max-WT-1140 {
  width: 100%;
  max-width: 1140px !important;
}

.max-WT-20Per {
  max-width: 20% !important;
  width: 100%;
}

.max-WT-33Per {
  max-width: 33.33% !important;
  width: 100%;
}

.max-WT-60Per {
  max-width: 60% !important;
  width: 100%;
}

.max-WT-65Per {
  max-width: 65% !important;
  width: 100%;
}

.max-WT-70Per {
  max-width: 70% !important;
  width: 100%;
}

.max-WT-75Per {
  max-width: 75% !important;
  width: 100%;
}

.max-WT-80Per {
  max-width: 80% !important;
  width: 100%;
}

.max-WT-85Per {
  max-width: 85% !important;
  width: 100%;
}

.max-WT-90Per {
  max-width: 90% !important;
  width: 100%;
}

.max-WT-95Per {
  max-width: 95% !important;
  width: 100%;
}

.minWidth20 {
  min-width: 20px;
}

.minWidth30 {
  min-width: 30px;
}

.minWidth35 {
  min-width: 35px;
}

.minWidth40 {
  min-width: 40px;
}

.minWidth50 {
  min-width: 50px;
}

.minWidth60 {
  min-width: 60px;
}

.minWidth70 {
  min-width: 70px;
}

.minWidth80 {
  min-width: 80px !important;
}

.minWidth90 {
  min-width: 90px;
}

.minWidth100 {
  min-width: 100px;
}

.minWidth110 {
  min-width: 110px;
}

.minWidth120 {
  min-width: 120px;
}

.minWidth130 {
  min-width: 130px;
}

.minWidth150 {
  min-width: 150px;
}

.minWidth200 {
  min-width: 200px;
}

.minWidth250 {
  min-width: 250px;
}

.minWidth300 {
  min-width: 300px;
}

.widthAuto {
  width: auto !important;
}

.Width10 {
  width: 10px !important;
}

.Width20 {
  width: 20px !important;
}

.Width30 {
  width: 30px !important;
}

.Width40 {
  width: 40px !important;
}

.Width50 {
  width: 50px !important;
}

.Width80 {
  width: 80px !important;
}

.Width100 {
  width: 100px !important;
}

.Width110 {
  width: 110px !important;
}

.Width120 {
  width: 120px !important;
}

.Width130 {
  width: 130px !important;
}

.Width140 {
  width: 140px !important;
}

.Width150 {
  width: 150px !important;
}

.Width170 {
  width: 170px !important;
}

.Width190 {
  width: 190px !important;
}

.Width200 {
  width: 200px !important;
}

.Width250 {
  width: 250px !important;
}

.Width300 {
  width: 300px !important;
}

.w45Per {
  width: 45%;
}

.w48Per {
  width: 48%;
}

.w100Per {
  width: 100%;
}

.heightAuto {
  height: auto !important;
}

.height30 {
  height: 30px !important;
}

.height40 {
  height: 40px !important;
}

.height34 {
  height: 34px !important;
}

.height100 {
  height: 100px !important;
}

.height135 {
  height: 135px !important;
}

.height150 {
  height: 150px !important;
}

.height200 {
  height: 200px !important;
}

.height250 {
  height: 250px !important;
}

.height300 {
  height: 300px !important;
}

.height350 {
  height: 350px !important;
}

.height400 {
  height: 400px !important;
}

.height500 {
  height: 500px !important;
}

.height550 {
  height: 550px !important;
}

.minHeight30 {
  min-height: 30px !important;
}

.minHeight34 {
  min-height: 34px !important;
}

.minHeight40 {
  min-height: 40px !important;
}

.minHeight45 {
  min-height: 45px !important;
}

.minHeight50 {
  min-height: 50px !important;
}

.minHeight60 {
  min-height: 60px !important;
}

.minHeight70 {
  min-height: 70px !important;
}

.minHeight80 {
  min-height: 80px !important;
}

.minHeight90 {
  min-height: 90px !important;
}

.minHeight100 {
  min-height: 100px !important;
}

.minHeight120 {
  min-height: 120px !important;
}

.minHeight150 {
  min-height: 150px !important;
}

.minHeight160 {
  min-height: 160px !important;
}

.minHeight170 {
  min-height: 170px !important;
}

.minHeight200 {
  min-height: 200px !important;
}

.minHeight250 {
  min-height: 250px !important;
}

.minHeight300 {
  min-height: 300px !important;
}

.minHeight350 {
  min-height: 350px !important;
}

.minHeight400 {
  min-height: 400px !important;
}

.minHeight500 {
  min-height: 500px !important;
}

.minHeight570 {
  min-height: 570px !important;
}

.minHeight600 {
  min-height: 600px !important;
}

.minHeight650 {
  min-height: 650px !important;
}

.minHeight700 {
  min-height: 700px !important;
}

.tableWidth1000 {
  min-width: 1000px;
}

.tableWidth900 {
  min-width: 900px;
}

.tableWidth800 {
  min-width: 800px;
}

.tableWidth600 {
  min-width: 600px;
}

.tableWidth500 {
  min-width: 500px;
}

.tableWidth400 {
  min-width: 400px;
}

.tableWidth300 {
  min-width: 300px;
}

.radius0 {
  border-radius: 0px !important;
}

.radius2 {
  border-radius: 2px !important;
}

.radius4 {
  border-radius: 4px !important;
}

.radius6 {
  border-radius: 6px !important;
}

.radius8 {
  border-radius: 8px !important;
}

.radius10 {
  border-radius: 10px !important;
}

.radius12 {
  border-radius: 12px !important;
}

.radius25 {
  border-radius: 25px !important;
}

.radius100 {
  border-radius: 100% !important;
}

.opacity2 {
  opacity: 0.2 !important;
}

.opacity3 {
  opacity: 0.3 !important;
}

.opacity4 {
  opacity: 0.4 !important;
}

.opacity5 {
  opacity: 0.5 !important;
}

.opacity6 {
  opacity: 0.6 !important;
}

.opacity7 {
  opacity: 0.7 !important;
}

.opacity8 {
  opacity: 0.8 !important;
}

.opacity9 {
  opacity: 0.9 !important;
}

.lineHeight1 {
  line-height: 1 !important;
}

.radius-TR0 {
  border-top-right-radius: 0 !important;
}

.radius-BR0 {
  border-bottom-right-radius: 0 !important;
}

.radius-TL0 {
  border-top-left-radius: 0 !important;
}

.radius-BL0 {
  border-bottom-left-radius: 0 !important;
}

.border {
  border: 1px solid #898989 !important;
}

.border0 {
  border: none !important;
}

.borderTop0 {
  border-top: none !important;
}

.borderRight0 {
  border-right: none !important;
}

.borderBottom0 {
  border-bottom: none !important;
}

.borderLeft0 {
  border-left: none !important;
}

.borderTop {
  border-top: 1px solid #898989 !important;
}

.borderRight {
  border-right: 1px solid #898989 !important;
}

.borderBottom {
  border-bottom: 1px solid #898989 !important;
}

.borderLeft {
  border-left: 1px solid #898989 !important;
}

.fontfamQB {
  font-family: "Quicksand-Bold";
}

.fontfamQM {
  font-family: "Quicksand-Medium";
}

.fontfamQR {
  font-family: "Quicksand-Regular" !important;
}

.fontfamTB {
  font-family: "Tommysoft-Bold";
}

.fontfamTR {
  font-family: "Tommysoft-Regular";
}

.fontfamTM {
  font-family: "Tommysoft-Medium";
}

.shadow0 {
  box-shadow: none !important;
}

.w-33 {
  width: 33.33%;
}

.tableAlignMiddle td,
.tableAlignMiddle th {
  vertical-align: middle !important;
}

.tableCellpadding td {
  padding: 8px !important;
}

.textInitial {
  text-transform: initial !important;
}

.textbrk p {
  word-break: break-all !important;
}

/*Start Checkbox design*/
.checkbox-design {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-weight: 600 !important;
  color: #686868 !important;
  font-size: 15px;
  min-height: 16px;
  margin: 0;
}

.checkbox-design a {
  color: #535f66 !important;
  text-decoration: underline;
}

.checkbox-design input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 3px;
}

.checkbox-design input[type="checkbox"] ~ span {
  position: absolute;
  left: -4px;
  top: 1px;
  width: 16px;
  height: 16px;
  border: 1px solid #737373;
  background: #fff;
  border-radius: 2px;
}

.checkbox-design input[type="checkbox"]:checked ~ span {
  /* border:1px solid #fff; background:#333; */
  border-radius: 2px;
}

.checkbox-design input[type="checkbox"] ~ span:before {
  opacity: 0;
  content: "";
  position: absolute;
  left: 4px;
  top: 0px;
  width: 6px;
  height: 10px;
  border-right: 2px solid #007119;
  border-bottom: 2px solid #007119;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  transform: rotate(0deg) scale(0);
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
}

.checkbox-design input[type="checkbox"]:checked ~ span:before {
  opacity: 1;
  transform: rotate(45deg) scale(1);
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
}

.checknew-design {
  position: relative;
  padding-left: 15px;
  padding-right: 2px;
  cursor: pointer;
  font-weight: 500;
  font-size: 10px;
  color: #d8d8d8 !important;
}

.checknew-design input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.checknew-design input[type="checkbox"] ~ span {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 12px;
  height: 11px;
  border: 1px solid #2ebe93;
  background: #018a61;
  border-radius: 2px;
}

.checknew-design input[type="checkbox"] ~ span:before {
  opacity: 0;
  content: "";
  position: absolute;
  left: 3px;
  top: 0px;
  width: 4px;
  height: 7px;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  transform: rotate(0deg) scale(0);
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
}

.checknew-design input[type="checkbox"]:checked ~ span:before {
  opacity: 1;
  transform: rotate(45deg) scale(1);
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
}

/*End Checkbox design*/

.radio-design {
  position: relative;
  padding-left: 20px;
  padding-right: 2px;
  cursor: pointer;
}

.radio-design input[type="radio"] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.radio-design input[type="radio"] ~ span {
  position: absolute;
  left: 0px;
  top: 5px;
  width: 14px;
  height: 14px;
  border: 1px solid #3a3a3a;
}

.radio-design input[type="radio"] ~ span:before {
  opacity: 0;
  content: "";
  position: absolute;
  left: 1px;
  top: 1px;
  width: 10px;
  height: 10px;
  background: #17a2b8;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  transform: scale(0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
}

.radio-design input[type="radio"]:checked ~ span:before {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
}

.largeRadio.radio-design {
  padding-left: 30px;
  color: var(--text-grey);
  /* margin-right: 30px; */
  font-weight: 100 !important;
}

.largeRadio.radio-design input[type="radio"] ~ span {
  width: 18px;
  height: 18px;
  top: 0;
  border-radius: 9px;
}

.largeRadio.radio-design input[type="radio"] ~ span:before {
  left: -1.4px;
  top: -0.9px;
  width: 18px;
  height: 18px;
}

.checkbox-design2.checkbox-design input[type="checkbox"] ~ span {
  border-color: #4cb275;
  border-radius: 3px;
}

.checkbox-design2.checkbox-design input[type="checkbox"] ~ span:before {
  background: #4cb275;
  border: none;
  transform: none;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  top: 4px;
}

.weight300 {
  font-weight: 300 !important;
}

.weight400 {
  font-weight: 400 !important;
}

.weight500 {
  font-weight: 500 !important;
}

.weight600 {
  font-weight: 600 !important;
}

.weight700 {
  font-weight: 700 !important;
}

.weightBold {
  font-weight: bold !important;
}

.font9 {
  font-size: 9px !important;
}

.font10 {
  font-size: 10px !important;
}

.font11 {
  font-size: 11px !important;
}

.font12 {
  font-size: 12px !important;
}

.font13 {
  font-size: 14px !important;
}

.font14 {
  font-size: 14px !important;
}

.font15 {
  font-size: 15px !important;
}

.font16 {
  font-size: 16px !important;
}

.font17 {
  font-size: 17px !important;
}

.font18 {
  font-size: 18px !important;
}

.font20 {
  font-size: 20px !important;
}

.font22 {
  font-size: 22px !important;
}

.font24 {
  font-size: 24px !important;
}

.font30 {
  font-size: 30px !important;
}

.bgTransparent {
  background: transparent !important;
}

.bgColorTransparent {
  background-color: transparent !important;
}

.bgWhite {
  background-color: #fff !important;
}

.bgGray {
  background-color: var(--white-color) !important;
}

.commonSectionStyle {
  padding: 9em 0;
}

.container1440,
.container1280 {
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

/* .container1440{max-width:1440px;} */
.container1280 {
  max-width: 1280px;
}

.bgBlue {
  background-color: #18234c;
}

.center-box {
  margin: 0 auto !important;
}

/* ================Form Style============= */
.formBox {
  font-family: "Quicksand-Regular";
}

.formBoxHead {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 30px;
  color: #4c4c4c;
}

.formBox .form-group {
  position: relative;
}

.formBox .control-label {
  /* font-size:14px;color:#777; */
  font-weight: 600;
}

.formBox .form-control {
  border-color: #d3d2d2;
  font-size: 14px;
  font-family: "Quicksand-Regular" !important;
  /* min-height:45px; */
  resize: none;
  border-radius: 4px;
  font-weight: 600;
}

.form-control.datepicker {
  background-image: url(../images/cal-icon.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 10px) 50%;
  padding-right: 30px !important;
  padding-left: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-color: #898989;
  width: 112px !important;
  font-size: 14px;
}

.forgotpass {
  font-size: 14px;
  color: #7a7a7a;
  text-decoration: none;
}

.formLinksInfo {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  margin: 0;
  font-family: "Quicksand-Bold";
  color: var(--text-grey);
}

.formLinksInfo a {
  color: #3e5c85;
  font-weight: 600;
  margin-left: 7px;
  font-family: "Quicksand-Bold";
}

.tooltipGroup {
  position: relative;
}

.tooltip {
  position: absolute;
  right: -40px;
  top: 15px;
  opacity: 1;
}

.tooltip i {
  color: #2f2f2f;
  font-size: 20px;
}

label.error,
span.error {
  margin: 0 !important;
  display: inline-block;
  width: 100%;
  color: #ff0000 !important;
  font-size: 22px !important;
  font-weight: 500 !important;
}

.errorMsgBox {
  background: #ff0000;
  padding: 10px;
  color: #fff;
  font-size: 14px;
}

.errorMsgBox.d-flex {
  flex-wrap: wrap;
}

.successBg {
  background: #00b328 !important;
}

.errorMsgBox i {
  margin-right: 7px;
}

.mobileGroup .input-group-prepend {
  margin-right: 10px;
}

.verfiyIcon {
  color: #00b328;
  position: absolute;
  right: 10px;
  top: 15px;
}

.grayShadow {
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.2);
}

.bgWhite {
  background: #fff !important;
}

.formBox .col-form-label {
  font-size: 14px;
}

.formActionBtn .btn + .btn {
  margin-left: 10px;
}

.btn.btnOrange {
  background: #ab4113;
  color: #fff;
  border-color: #ab4113;
}

.btnMid {
  min-height: 45px;
}

.formBackdrop {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 30px;
}

.wColor.form-control:-moz-placeholder {
  color: #a0a0a0 !important;
}

.wColor.form-control::-moz-placeholder {
  color: #a0a0a0 !important;
}

.wColor.form-control:-webkit-input-placeholder {
  color: #a0a0a0 !important;
}

.wColor.form-control::-webkit-input-placeholder {
  color: #a0a0a0 !important;
}

.uploadFile {
  position: relative;
  color: #008dea;
  cursor: pointer;
}

.uploadFile input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  opacity: 0;
}

.uploadFile img {
  margin-right: 7px;
  max-width: 30px;
}

/* ==================Modal Style=============== */
body.modal-open,
.modal {
  padding-right: 0 !important;
}

.head4,
.head5 {
  color: #0c3364;
  margin: 15px 0;
}

.head4 {
  font-size: 24px;
}

.head5 {
  font-size: 18px;
}

.modal button.close,
.btn.closeChatPanel {
  background: #ff0000;
  opacity: 1;
  color: #fff;
  font-family: arial;
  font-weight: 500;
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  line-height: 20px;
  font-size: 18px;
  z-index: 9;
}

.close.closePos1 {
  position: absolute;
  right: -8px;
  top: -8px;
}

.modal p,
.staticContent p {
  font-size: 14px;
  /* color: #808080; */
}

.underline {
  text-decoration: underline;
}

.gColor {
  color: #0aa619 !important;
}

.wColor {
  color: #fff !important;
}

.bColor {
  color: #043d8a !important;
}

.rColor {
  color: #e64b53 !important;
}

.blackColor {
  color: #000 !important;
}

.greyColor {
  color: var(--text-grey) !important;
}

.dBlueColor {
  color: #3e5c85 !important;
}

.orangeColor {
  color: #ff8000 !important;
}

.lBlueColor {
  color: #30b5d1 !important;
}

.verCenterModal {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.globalModal1 .modal-header {
  position: relative;
  border: none;
  display: block;
  border-color: #898989;
}

.globalModal1 .modal-title {
  border-bottom: 1px solid #ddd;
  display: block;
  padding-bottom: 10px;
  margin: 0;
  font-size: 16px;
  color: #4b4b4b;
  font-weight: 500;
  font-family: "Orkney";
  padding-left: 10px;
  text-transform: uppercase;
}

.globalModal1 .modal-header .close {
  position: absolute;
  top: 5px;
  right: 9px;
}

/* .globalModal1 .modal-header,.globalModal1 .modal-body,.globalModal1 .modal-footer{padding:10px;} */
.globalModal1 .modal-footer {
  border: none;
}

.globalModal1 .sectionHead h2 {
  font-size: 22px;
}

.globalModal1 .sectionHead p {
  margin-top: 7px;
}

.otpField {
  justify-content: space-between;
}

.otpField input {
  width: calc(25% - 7px);
}

.otpField.otpSixField input {
  width: calc(16.16% - 7px);
}

.lightBgGray {
  background: #f2f2f2;
}

/* ===============Tab Style========= */
.nav-tabs {
  /* border-bottom: 1px solid #dee2e6; */
  border-bottom: none !important;
}

.nav-tabs.globalTab1 .nav-item + .nav-item {
  margin-left: 50px;
}

.nav-tabs.globalTab1 .nav-link {
  padding: 0;
  display: block;
}

.nav-tabs.globalTab {
  /* border-bottom:4px solid #17a2b8; */
  margin-bottom: 30px !important;
}

.nav-tabs.globalTab .nav-item {
  margin-bottom: 0;
}

.nav-tabs.globalTab .nav-item + .nav-item {
  padding-left: 5px;
}

.nav-tabs.globalTab .nav-link {
  min-height: 52px;
  background: #fff;
  color: #3e5c85;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  text-transform: uppercase;
  align-items: center;
  display: grid;
  border: 1px solid #3e5c85;
}

.nav-tabs.globalTab .nav-link.active {
  background-color: #3e5c85;
  color: #fff;
}

/* .globalTab.transparentTabs.nav-tabs .nav-link.active{background:#ffcc00;}  */
.nav-tabs .nav-link {
  border-top-left-radius: none !important;
  border-top-right-radius: none !important;
}

.globalTab.transparentTabs.nav-tabs {
  border: 1px solid #737373;
  overflow: hidden;
  border-radius: 5px;
}

.globalTab.transparentTabs .nav-item {
  margin-bottom: 0;
}

.globalTab.transparentTabs .nav-item + .nav-item .nav-link {
  border-left: 1px solid #737373;
}

.globalTab.transparentTabs.nav-tabs .nav-link {
  background: #fff;
  color: #878787;
  font-weight: 500;
}

/* =============Table Design=============== */
.filterSearch {
  position: relative;
  width: 300px;
}

.filterSearch .form-control {
  padding-left: 40px;
}

.filterSearch i {
  position: absolute;
  font-size: 17px;
  left: 13px;
  top: 14px;
  color: #7f7f7f;
}

.tableBoxBody {
  border-top: none;
  border-bottom: none;
}

.globalTable1 table {
  margin: 0;
  border: none !important;
}

.globalTable1 table thead tr th {
  color: #fff;
  font-weight: 600;
  background-color: #00851e;
  border-bottom: none;
}

.globalTable1 table thead tr th,
.globalTable1 table tbody tr td {
  font-size: 15px;
  border-color: #c3c3c3;
  font-family: "Calibri";
  padding: 8px;
}

.globalTable1 table tbody tr td {
  color: #9f9f9f;
}

.firstLastBorderHide th:first-child,
.firstLastBorderHide td:first-child {
  border-left: none !important;
}

.firstLastBorderHide th:last-child,
.firstLastBorderHide td:last-child {
  border-right: none !important;
}

.skyBColor {
  color: var(--btn-color) !important;
}

.tableLayoutFixed {
  table-layout: fixed;
}

.globalTable1 table thead tr + tr th {
  font-size: 12px;
  font-weight: 500;
}

.globalTable1 .checkbox-design {
  min-height: 14px;
  margin: 0;
}

.globalPagination {
  padding: 15px 0;
}

.globalPagination .pagination {
  margin: 0;
  justify-content: flex-end;
}

.globalPagination .page-item .page-link {
  color: #4b4b4b;
  box-shadow: none !important;
  font-size: 15px;
}

.globalPagination .page-item.active .page-link,
.globalPagination .page-item .page-link:hover,
.globalPagination .page-item .page-link:focus {
  background-color: #4b4b4b !important;
  color: var(--white-color) !important;
  border-color: #898989 !important;
}

.tableTotal {
  font-weight: 600;
  padding: 10px 8px;
  color: #4b4b4b;
}

.smallProImg {
  width: 85px;
  height: 85px;
  border-radius: 6px;
  border: 1px solid #898989;
  display: flex;
  padding: 5px;
  align-items: center;
  margin: 0 auto;
}

.btnTransaparent {
  background: transparent !important;
  padding: 0;
  border: none;
  cursor: pointer;
}

/* ===============Button Style=============== */

.btn.btnRed {
  background-color: #e64a52;
  color: var(--white-color);
}

/* =================Rating Recieved=========== */
.prodRating {
  width: 85px;
}

.prodRating i {
  font-size: 12px;
  color: #bebebe;
  display: inline-block;
}

.prodRating i + i {
  margin-left: 3px;
}

/* ===================Accordian 2============= */
/* .accordianBox + .accordianBox{margin-top:30px; padding: 0 20px;} */
.accordianBox {
  margin-top: 30px;
  padding: 0 20px;
}

.globalAccordian .card {
  border: none;
}

.globalAccordian .card + .card {
  margin-top: 15px;
}

.qno {
  font-weight: 600;
  color: #4b4b4b;
  position: absolute;
  left: 10px;
  top: 11px;
  font-size: 14px;
}

.globalAccordian .card-header {
  position: relative;
  border: none;
  box-shadow: none;
  padding: 0;
  background: transparent;
}

.globalAccordian .card-header .btn-link {
  width: 100%;
  white-space: normal;
  padding: 10px 40px 10px 40px;
  border-radius: 4px;
  color: #4b4b4b !important;
  text-align: left;
  text-decoration: none !important;
  border: 1px solid #898989;
  position: relative;
  font-weight: 500;
  background: transparent;
}

.globalAccordian .card-header .btn-link:after {
  content: "\f078";
  font-family: Font Awesome\ 5 Free;
  font-weight: 900;
  position: absolute;
  right: 10px;
  top: 10px;
  background: var(--primary-color);
  color: white;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 27px;
}

.globalAccordian .card-header .btn-link[aria-expanded="true"] {
  border-bottom: 1px solid #898989;
  /* background:#cfcfcf;color:#464646; */
}

.globalAccordian .card-header .btn-link[aria-expanded="true"]:after {
  content: "\f077";
}

.globalAccordian .card-header .btn-link[aria-expanded="true"] + .qno {
  color: #464646;
}

.ans {
  color: #70a45b;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
  font-family: "Quicksand-Bold";
  line-height: 1;
}

.globalAccordian .card-body {
  font-family: "Quicksand-Regular";
  font-size: 14px;
  border-color: #898989;
  padding-left: 0;
  padding-right: 0;
}

.globalAccordian .card-body p {
  margin: 10px 0 0;
}

.accordBoxHead {
  color: var(--text-grey);
  font-family: "Quicksand-Bold";
  font-weight: 500;
  margin: 0 0 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.accordBoxHead img {
  max-width: 20px;
  margin-right: 7px;
}

/* ================Header DropDown=========== */
.head-user-img img,
.dropUserDetail img {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}

.dropdown-menu.head-drop-down {
  right: 0px;
  left: auto;
  width: 260px;
  padding: 10px;
  border-radius: 6px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  top: 100% !important;
  transform: none !important;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu.head-drop-down.show:before {
  content: "";
  border-bottom: 10px solid var(--white-color);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: -9px;
  right: 12px;
}

.dropdown-menu.head-drop-down a {
  padding-left: 10px;
  padding-right: 10px;
  background: var(--white-color);
  font-size: 15px;
  color: var(--text-grey);
}

.dropdown-menu.head-drop-down a + a {
  border-top: 1px solid #898989;
  padding-top: 7;
  margin-top: 7;
}

.top_user_info .dropdown-toggle:after {
  display: none;
}

.dropUserDetail {
  padding: 0px 10px;
  margin-bottom: 7px;
}

.dropUserDetail h4 {
  font-size: 15px;
  margin: 10px 0 0;
  line-height: 1;
}

.dropUserDetail p {
  margin: 0;
  font-size: 13px;
}

/* ============Rating====== */
.globalRating {
  width: 85px;
  display: flex;
  align-items: center;
}

.rated {
  color: #f1c40f !important;
}

.globalRating i {
  font-size: 12px;
  color: #bebebe;
}

.kycfile-uplaod {
  display: block;
  width: 100%;
  border: 1px solid #ced4da;
  max-width: 100%;
  height: 45px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}

.kycfile-uplaod input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  opacity: 0;
}

.kycfile-uplaod span.label-control {
  color: #495057;
  position: absolute;
  left: 15px;
  top: 11px;
}

.kycfile-uplaod i {
  position: absolute;
  right: 10px;
  top: 12px;
  color: #495057;
  font-size: 18px;
  opacity: 0.8;
}

/* =====================progress Chart=========== */

.progress-pie-chart {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--white-color);
  position: relative;
}

.progress-pie-chart.gt-50 {
  background-color: #94ba33;
}

.ppc-progress {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  clip: rect(0, 100px, 100px, 50px);
}

.ppc-progress .ppc-progress-fill {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  clip: rect(0, 50px, 100px, 0);
  background: #94ba33;
  transform: rotate(60deg);
}

.gt-50 .ppc-progress {
  clip: rect(0, 50px, 100px, 0);
}

.gt-50 .ppc-progress .ppc-progress-fill {
  clip: rect(0, 100px, 100px, 50px);
  background: var(--white-color);
}

.ppc-percents {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - 73.91304px / 2);
  top: calc(50% - 73.91304px / 2);
  width: 73.91304px;
  height: 73.91304px;
  background: var(--white-color);
  text-align: center;
  display: table;
}

.ppc-percents span {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #81ce97;
}

.pcc-percents-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.progress-pie-chart {
  margin: 0 auto 15px;
}

.countCss {
  display: none;
}

/* new theme css dev_sanjeev */
.darkBlue_bg {
  background: #0f0230;
}

.blue_gri {
  background: linear-gradient(to right, #161654 0%, #0f0230 100%);
}

.newBtn {
  /* background: #0b0125; */
  /* background: linear-gradient(
        45deg,
        rgba(218, 7, 162, 1) 0%,
        rgba(93, 68, 165, 1) 47%,
        rgba(53, 176, 230, 1) 100%
      ); */
  /* background: linear-gradient( 45deg, rgb(195 40 29) 0%, rgb(67 9 5) 47%, rgb(16 2 10) 100% ); */
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#da07a2', endColorstr='#35b0e6', GradientType=1 ); */
  height: 40px;
  border-radius: 50px;
  padding: 3px 3px;
  /* color: #fff!important; */
  font-size: 13px;
  letter-spacing: 0.25px;
  min-width: 120px;
  font-weight: bold;
  border: none;
  /* box-shadow: 1px 1px 8px 6px #ff61ff;  */
  /* box-shadow:1px 1px 8px 6px #d3f9ff; */
  text-align: center;
  /* margin-right: 20px;  */
}

/* .menu ul li a{color:#121212;} */
.newBtn:hover {
  /* box-shadow: 1px 5px 8px 6px #d3f9ff; */
  /* transform:translateY(-5px); */
  border: 1px solid var(--btn-color);
  border-radius: 8px;
}

.newBtn:hover a {
  color: var(--btn-color) !important;
}

.btnPad {
  padding: 10px 10px !important;
}

.minhyt {
  height: 35px !important;
}

.buyBtn {
  background: #0b0125;
  /* background: linear-gradient( 45deg, rgb(195 40 29) 0%, rgb(67 9 5) 47%, rgb(16 2 10) 100% ); */
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#da07a2', endColorstr='#35b0e6', GradientType=1 ); */
  height: 50px;
  border-radius: 50px;
  padding: 10px 10px;
  color: var(--white-color);
  font-size: 13px;
  letter-spacing: 0.25px;
  min-width: 130px;
  font-weight: bold;
  border: none;
  /* box-shadow: 1px 1px 8px 6psx #ff61ff;  */
  /* box-shadow: 1px 1px 8px 6px #f3a8a4; */
  text-align: center;
  margin-right: 20px;
}

.buyBtn:hover {
  /* background: linear-gradient(
        45deg,
        rgba(218, 7, 162, 1) 0%,
        rgba(93, 68, 165, 1) 47%,
        rgba(53, 176, 230, 1) 100%
      ); */
  background: #0b0125;
  box-shadow: 1px 5px 8px 6px #d3f9ff;
  /* transform: translateY(-5px); */
  transform: translateY(-5px);
}

.worth {
  text-align: center;
  background-color: #3e5c85;
  color: white;
  padding: 7px;
  margin-bottom: 5px;
  border-radius: 10px;
}

/* --Mobile Responssive----- */
@media (max-width: 736px) {
  .btnPad {
    padding: 5px 6px !important;
  }

  .newBtn {
    min-width: 60px;
    height: 35px;
    margin-right: 15px;
  }
}

.nobillioTableHead {
  position: sticky;
  top: -1px;
  background-color: #3e5c85;
  width: 100vw;
}

.nobillioTableHead tr {
  color: white;
}

.nobillioTableBody tr:hover {
  background-color: #dadadaa3;
}

.nobillioTableBody tr td {
  color: var(--black-color);
  font-weight: bold;
}

.attch_vid{
  position: relative;
}

.attch_vid button{
  position: absolute;
  margin: 35% 0 0 -58%;
  width: 39px;
  border: 0px solid;
}

.txn_csv{
  display: inline-block;
  position: relative;
  width: 100%;
}
.txn_csv div{
  display: inline-block;
  width: 50%;
}
.txn_csv .csv_b{
  text-align: right
}

/* .chat-inner-box p {
  overflow: hidden;
  word-break: break-all;
} */

.cke_notification_warning{
  display: none;
}

.cardBox-mt50-height577 {
  height: 535px;
  margin-top: 50px;
  border-radius: 12px;
  box-shadow: 0 4px 20px #cac7c7;
}