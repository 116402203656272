body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color: #3e5c85 !important;
  --popUpBW-color: #3e5c85 !important;
  --border-color: #e7eaee !important;
  --hover-color: #009cec !important;
  --btn-color: #30b5d1 !important;
  --white-color: #fff !important;
  --black-color: #000 !important;
  --box-shadow: #8c8c8c !important;
  --text-grey: #3a3a3a !important;
  --text-white: #fff !important;
  --table-hover: #374354 !important;
  --dark-btn: "000000ad" !important;
}
