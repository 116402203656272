$white: #ffffff;
$lightgrey: #ffffff;
$midgrey: #35b0e6;
$darkgrey: #554842;
$red: #f01a19;
$darkred: #da07a2;

.rocket {
  position: absolute;
  top: 22%;
  width: 80px;
  left: calc(80% - 50px);
.rocket_body {
  width: 80px;
  left: calc(50% - 50px);
  animation: bounce 0.5s infinite;
  .bodyin {
    background:linear-gradient(
      45deg, #da07a2 0%, #5d44a5 47%, #35b0e6 100%);;
    height: 253px;
    width: 130%;
    left: calc(50% - 50px);
    border-top-right-radius: 100%;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top: 5px solid $midgrey;
  }
  &:before {
    content: '';
    position: absolute;
    left: calc(58% - 24px);
    width: 60px;
    height: 13px;
    background-color: $white;
    bottom: -13px;
    border-bottom-right-radius: 60%;
    border-bottom-left-radius: 60%;
  }
}
.window {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: $darkred;
  left: calc(63% - 25px);
  top: 54px;
  border: 5px solid $white;
}
.fin_left {
  position: absolute;
  z-index: -100;
  height: 75px;
  width: 75px;
  background-color: $darkred;
  left: -55px;
  top: calc(91% - 55px);
  border-top-left-radius: 80%;
  border-bottom-left-radius: 20%;
}
.fin_right {
  position: absolute;
  z-index: -100;
  height: 75px;
  width: 75px;
  background-color: $darkred;
  right: -80px;
  top: calc(91% - 55px);
  border-top-right-radius: 80%;
  border-bottom-right-radius: 20%;
}
.exhaust_flame {
  position: absolute;
  top: 90%;
  width: 28px;
  background: linear-gradient(to bottom, transparent 10%, $white 100%);
  height: 150px;
  left: calc(64% - 14px);
  animation: exhaust 0.2s infinite;
}
.exhaust_fumes li {
  margin-top:70px;
  width: 60px;
  height: 60px;
  background-color: $white;
  list-style: none;
  position: absolute;
  border-radius: 100%;
  &:first-child {
    width: 200px;
    height: 200px;
    bottom: -300px;
    animation: fumes 5s infinite;
  }
  &:nth-child(2) {
    width: 150px;
    height: 150px;
    left: -120px;
    top: 260px;
    animation: fumes 3.2s infinite;
  }
  &:nth-child(3) {
    width: 120px;
    height: 120px;
    left: -40px;
    top: 330px;
    animation: fumes 3s 1s infinite;
  }
  &:nth-child(4) {
    width: 100px;
    height: 100px;
    left: -170px;
    animation: fumes 4s 2s infinite;
    top: 380px;
  }
  &:nth-child(5) {
    width: 130px;
    height: 130px;
    left: -120px;
    top: 350px;
    animation: fumes 5s infinite;
  }
  &:nth-child(6) {
    width: 200px;
    height: 200px;
    left: -60px;
    top: 280px;
    animation: fumes2 10s infinite;
  }
  &:nth-child(7) {
    width: 100px;
    height: 100px;
    left: -100px;
    top: 320px;
  }
  &:nth-child(8) {
    width: 110px;
    height: 110px;
    left: 70px;
    top: 340px;
  }
  &:nth-child(9) {
    width: 90px;
    height: 90px;
    left: 200px;
    top: 380px;
    animation: fumes 20s infinite;
  }
}
}

@keyframes fumes {
50% {
  transform: scale(1.5);
  background-color: transparent;
}
51% {
  transform: scale(0.8);
}
100% {
  background-color: $white;
  transform: scale(1)
}
}

@keyframes bounce {
0% {
  transform: translate3d(0px, 0px, 0);
}
50% {
  transform: translate3d(0px, -4px, 0);
}
100% {
  transform: translate3d(0px, 0px, 0);
}
}

@keyframes exhaust {
0% {
  background: linear-gradient(to bottom, transparent 10%, $white 100%);
}
50% {
  background: linear-gradient(to bottom, transparent 8%, $white 100%);
}
75% {
  background: linear-gradient(to bottom, transparent 12%, $white 100%);
}
}

@keyframes fumes2 {
50% {
  transform: scale(1.1);
}
}

@keyframes twinkle {
80% {
  transform: scale(1.1);
  opacity: 0.7;
}
}
